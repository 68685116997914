import Axios from "./axios";

export const getSpRequestIndividual = async (query) => {
  return await Axios.get(
    `get-sp-request-individual?page=${query?.page}&limit=${query?.limit}&status=${query?.status}&searchKeyword=${query?.searchQuery}`
  );
};
export const getSpRequestCompany = async (query) => {
  return await Axios.get(
    `get-sp-request-company?page=${query?.page}&limit=${query?.limit}&status=${query?.status}&searchKeyword=${query?.searchQuery}`
  );
};
export const getSpdetail = async (data) => {
  return await Axios.post(`get-sp-detail/`, data);
};
export const spApprove = async (data) => {
  return await Axios.post(`sp-approve/`, data);
};
export const spReject = async (data) => {
  return await Axios.post(`reject-sp/`, data);
};
export const deleteSp = async (id) => {
  return await Axios.delete(`delete-sp/${id}`);
};

export const getRewardOrderList = async (query) => {
  return await Axios.get(
    `reward-redeem-booking?page=${query?.page}&limit=${query?.limit}&searchKeyword=${query?.searchQuery}`
  );
};

export const getBookingDetail = async (id) => {
  return await Axios.get(`get-booking-detail/${id}`);
};
export const cancelBookingApi = async (data) => {
  return await Axios.post(`reject-booking`, data);
};
export const getSpListingApi = async (area_id, cat_id) => {
  return await Axios.get(
    `get-sp-listing?area_id=${area_id}&category_id=${cat_id}`
  );
};
export const spApproveApi = async (data) => {
  return await Axios.post(`admin-booking-approve`, data);
};
export const submitOffer = async (data) => {
  return await Axios.post(`submit-offer`, data);
};
export const deleteBooking = async (booking_id) => {
  return await Axios.delete(`delete-booking/${booking_id}`);
};
export const getEditRequestIndividual = async (query) => {
  return await Axios.get(
    `get-tracking-data-ind?page=${query?.page}&limit=${
      query?.limit
    }&searchKeyword=${query?.searchQuery ?? ""}`
  );
};
export const getEditRequestCompany = async (query) => {
  return await Axios.get(
    `get-tracking-data-comp?page=${query?.page}&limit=${
      query?.limit
    }&searchKeyword=${query?.searchQuery ?? ""}`
  );
};
export const getEditRequestDetail = async (id) => {
  return await Axios.get(`get-tracking-detail/${id}`);
};
export const rejectEditRequest = async (data) => {
  return await Axios.post(`reject-edit-request`, data);
};
export const approveEditRequest = async (id) => {
  return await Axios.put(`accept-edit-request/${id}`);
};
export const getSpListingInd = async (query) => {
  return await Axios.get(
    `get-service-provider-ind?page=${query?.page}&limit=${
      query?.limit
    }&searchKeyword=${query?.searchQuery ?? ""}&status=${
      query?.status ?? ""
    }&startDate=${query?.startDate ?? ""}&endDate=${query?.endDate ?? ""}`
  );
};
export const getSpListingUnregister = async (query) => {
  return await Axios.get(
    `get-service-provider-unregister?page=${query?.page}&limit=${
      query?.limit
    }&searchKeyword=${query?.searchQuery ?? ""}&status=${
      query?.status ?? ""
    }&startDate=${query?.startDate ?? ""}&endDate=${
      query?.endDate ?? ""
    }&type=${query?.type ?? ""}`
  );
};

export const getSpListingUnregisterComp = async (query) => {
  return await Axios.get(
    `get-service-provider-unregister-comp?page=${query?.page}&limit=${
      query?.limit
    }&searchKeyword=${query?.searchQuery ?? ""}&status=${
      query?.status ?? ""
    }&startDate=${query?.startDate ?? ""}&endDate=${query?.endDate ?? ""}`
  );
};
export const getSpListingComp = async (query) => {
  return await Axios.get(
    `get-service-provider-comp?page=${query?.page}&limit=${
      query?.limit
    }&searchKeyword=${query?.searchQuery ?? ""}&status=${
      query?.status ?? ""
    }&startDate=${query?.startDate ?? ""}&endDate=${query?.endDate ?? ""}`
  );
};

export const addNoteSpApi = async (data) => {
  return await Axios.post(`add-note-sp`, data);
};
export const getAllBookingSpApi = async (id) => {
  return await Axios.get(`get-all-booking-sp/${id}`);
};
export const getAreaApi = async () => {
  return await Axios.get(`area/get-area`);
};
export const getCategoryApi = async () => {
  return await Axios.get(`category/getCategory`);
};
export const deleteAreaApi = async (id) => {
  return await Axios.delete(`delete-area-admin/${id}`);
};
export const deleteCategoryApi = async (id) => {
  return await Axios.delete(`delete-category-admin/${id}`);
};
export const spIndBasicAuthApi = async (data) => {
  return await Axios.post(`admin/sp/add-individualSp`, data);
};
export const chooseAreaApi = async (data) => {
  return await Axios.post(`admin/sp/choose-area`, data);
};
export const chooseCategoryIndApi = async (data) => {
  return await Axios.post(`admin/sp/choose-category-ind`, data);
};
export const spCompBasicAuthApi = async (data) => {
  return await Axios.post(`admin/sp/add-companySp`, data);
};
export const chooseCategoryCompApi = async (data) => {
  return await Axios.post(`admin/sp/choose-category-comp`, data);
};
export const UploadRegistrationIdIndApi = async (data) => {
  return await Axios.post(`admin/sp/add-registration-id-ind`, data);
};
export const uploadContractApi = async (data) => {
  return await Axios.post(`admin/sp/upload-sp-contract`, data);
};
export const addPortfolioApi = async (data) => {
  return await Axios.post(`admin/sp/portfolio-sp`, data);
};
export const deletePortfolioApi = async (data) => {
  return await Axios.post(`admin/sp/delete-portfolio`, data);
};
export const addUpdateComputerCardApi = async (data) => {
  return await Axios.post("admin/sp/add-computer-card", data);
};
export const addUpdateTradeLicenseApi = async (data) => {
  return await Axios.post("admin/sp/add-trade-license", data);
};
export const addUpdateQatarIdApi = async (data) => {
  return await Axios.post("admin/sp/add-qatar-id", data);
};
export const handleCommercialRegistrationApi = async (data) => {
  return await Axios.post("admin/sp/add-commercial-registration", data);
};
export const addSubscriptionApi = async (data) => {
  return await Axios.post("add-subscription", data);
};
export const getSubscriptionApi = async (id) => {
  return await Axios.get(`get-subscription/${id}`);
};
export const deleteSubscriptionApi = async (id) => {
  return await Axios.post(`admin/delete-subscription/${id}`);
};
export const updateStatusSpApi = async (data) => {
  return await Axios.post("update-status-sp", data);
};
export const getBadgeSpApi = async (id) => {
  return await Axios.get(`admin/badge/get-alloted-badge-sp/${id}`);
};
export const allotBadgeApi = async (data) => {
  return await Axios.post("admin/badge/allot-badge", data);
};
export const deleteBadgeSpApi = async (data) => {
  return await Axios.post("admin/badge/delete-alloted-badge", data);
};
